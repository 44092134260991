
.list-card {
    margin: 20px;
    padding: 16px;
}

.subtitle-spacing {
    margin-bottom: 10px;
}

.list-card img.list-card-image {
    width: 40%;
    height: auto;
    max-height: 120px;
    padding: 16px;
    margin: auto;
}

@media (max-width: 480px) {
    .list-card img.list-card-image {
        width: 60%; 
    }
}
