
.item-list-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.item-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #4f52b2;
}

.item-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1; 
    margin-right: 10px; 
}

.item-buttons {
    display: flex;
    gap: 10px; 
}

.item-buttons button {
    min-width: auto !important;
    width: auto; 
    padding: 4px;
    margin: 0; 
}


.button-text {
    display: inline;
}


@media (max-width: 600px) {
    .item-buttons {
        gap: 5px; 
    }

    .button-text {
        display: none;
    }

    .item-item {
        justify-content: flex-end;
    }
}
